import {isNullOrUndefined} from "../../../services/Validator";

function GamesScreenContentLayout({children}) {
    // Used in KnowledgesContent to frame Knowledges
    // map

    // BUILD
    if (isNullOrUndefined(children))
        return null
    return (
        <div className={"animate-appearSlow overflow-auto w-[560px] md:w-[640px] lg:w-[720px] grow flex flex-col justify-start items-start"}>
            {children}
        </div>
    );
}

// EXPORT
export default GamesScreenContentLayout