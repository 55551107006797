
// List of constant images
// ---- flashScreen
export const kFlashImages = {
    logoText: '/images/SystemFlashLogoText.png',
    noGradeSelected: "/images/flashScreens/parameterStepScreen/penAndPaperBear.png",
    resultsEmojiBearSuccess: "/images/flashScreens/resultsStepScreen/emojiBearSuccess.png",
    resultsEmojiBearAlmost: "/images/flashScreens/resultsStepScreen/emojiBearAlmost.png",
    resultsEmojiBearAverage: "/images/flashScreens/resultsStepScreen/emojiBearAverage.png",
    resultsEmojiBearSceptic: "/images/flashScreens/resultsStepScreen/emojiBearSceptic.png",
    resultsEmojiBearConfused: "/images/flashScreens/resultsStepScreen/emojiBearConfused.png",
}

// ---- Games
export const kGamesImages = {
    bearLooseImage: "/images/games/emojiBearConfused.png",
    bearRecordImage: "/images/games/emojiBearSuccess.png",
    bearSuccessImage: "/images/games/emojiBearAverage.png",
}

// ---- gamesScreen
export const kGamesScreenImages = {
    multiplicationBackground: "/images/gamesScreen/multiplicationBackground.png",
    relativeSumBackground: "/images/gamesScreen/relativeSumBackground.png",
}

// ---- knowledgesScreen
export const kKnowledgesImages = {
    noGradeSelected: "/images/knowledgesScreen/knowledgesBearGradeImage.png"
}

// ---- previewScreen
export const kPreviewImages = {
    logo: '/images/previewScreen/previewLogo.png',
    logoText: '/images/previewScreen/previewLogoText.png'
}

// ---- SYSTEM
export const kSystemImages = {
    flashLogo: "/images/SystemFlashLogo.png",
    getItOnAppStore: "/images/SystemGetItOnAppStore.png",
    getIsOnPlayStore: "/images/SystemGetItOnPlayStore.png",
    dividerStar: "/images/SystemQuestionDividerStar.png",
    routeNotFound: "/images/System404ErrorImage.png",
    iceBlink: "/images/SystemIceBlink.png",
    iceConfused: "/images/SystemIceConfused.png",
    iceError: "/images/SystemIceError.png",
    iceNormal: "/images/SystemIceNormal.png",
    iceSkeptic: "/images/SystemIceSkeptic.png",
    iceStar: "/images/SystemIceStar.png",
    ummiaLogoText: '/images/SystemUmmiaLogoText.png',
}