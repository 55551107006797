
function GamesItemBackgroundImage({backgroundImage}) {
    // Used in GamesItem to display single Game background
    // image

    // BUILD
    return (
        <div className={"bg-red-100 w-[160px] min-w-[160px] h-[160px] min-h-[160px] flex justify-center items-center"}>
            <img className={"object-cover h-full"}
                 src={backgroundImage}
                 alt={""} />
        </div>
    );
}

// EXPORT
export default GamesItemBackgroundImage