import {kGamesImages} from "../../../constants/images";
import Lottie from 'react-lottie-player'
import animationData from '../../../animations/games/fireworksAnimation.json'

function GamesResultImage() {
    // Used in GameRecordResultContent to display
    // image with background firework animation

    // BUILD
    return (
        <div className={"relative w-[144px] min-w-[144px] h-[92px] flex justify-center items-center"}>
            <Lottie
                loop
                animationData={animationData}
                play
                style={{ width: 144, height: 120, transform: 'translateY(-24px)' }} />
            <img className={"absolute z-10 object-contain"}
                 alt={""}
                 src={kGamesImages.bearRecordImage} />
        </div>
    );
}

// EXPORT
export default GamesResultImage