import {kSystemDontKnowOption} from "../../../../constants/objects";
import {isArrayNullUndefinedOrEmpty} from "../../../../services/Validator";
import QuestionsMobileCardOptionItem
    from "../../../items/flashScreens/questionsStepScreen/QuestionsMobileCardOptionItem";

function QuestionsMobileCardOptionsList({options,
                                        optionSelected,
                                        setOptionSelected,
                                        onDontKnowPress}) {
    // Used in PreviewMobileQuestionsCard to display list of question
    // options and a default DontKnow option

    // BUILD
    if (isArrayNullUndefinedOrEmpty(options))
        return null
    return (
        <div className={"w-full grow flex flex-col px-16 space-y-12 mt-16"}>
            {/** LIST */}
            {options.map((option, index) => (
                <QuestionsMobileCardOptionItem key={index}
                                               isLast={false}
                                               option={option}
                                               optionSelected={optionSelected}
                                               setOptionSelected={setOptionSelected}
                                               onDontKnowPress={onDontKnowPress} />
            ))}
            {/** CONSTANT ITEM */}
            <QuestionsMobileCardOptionItem isLast={true}
                                           option={kSystemDontKnowOption}
                                           optionSelected={optionSelected}
                                           setOptionSelected={setOptionSelected}
                                           onDontKnowPress={onDontKnowPress} />
        </div>
    );
}

// EXPORT
export default QuestionsMobileCardOptionsList