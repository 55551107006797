import {kGamesList} from "../../../constants/objects";
import GamesItem from "../../items/gamesScreen/GamesItem";

function GamesList() {
    // Used in GamesScreenContent to display list of
    // local Games

    // BUILD
    return (
        <div className={"w-full flex flex-col space-y-24 mt-24"}>
            {kGamesList.map((game, index) => {
                return <GamesItem key={index}
                                  game={game} />
            })}
        </div>
    );
}

// EXPORT
export default GamesList