import {kGamesImages} from "../../../constants/images";

function GamesResultImage({isLost}) {
    // Used in

    // BUILD
    return (
        <div className={"w-[120px] min-w-[120px] h-[80px] flex justify-center items-center"}>
            <img className={"object-contain w-full h-full"}
                 alt={""}
                 src={isLost ? kGamesImages.bearLooseImage : kGamesImages.bearSuccessImage} />
        </div>
    );
}

// EXPORT
export default GamesResultImage