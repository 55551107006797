
function GamesResultsSectionText({text}) {
    // Used in Games result contents to display title text of
    // fade in text lists like advices or notes

    // BUILD
    return (
        <div className={"w-full flex justify-start mt-16"}>
            <span className={"text-[16px] font-medium text-greyLight9"}>
                {text}
            </span>
        </div>
    );
}

// EXPORT
export default GamesResultsSectionText