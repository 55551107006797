import GamesLeaveButton from "../../buttons/games/GamesLeaveButton";
import GamesTimeLeft from "../../others/games/GamesTimeLeft";
import GamesCurrentScore from "../../others/games/GamesCurrentScore";

function GamesTopBar({gameTimeLeft, currentScore}) {
    // Used in Games to display top bar made of
    // timer/score block and leave game button

    // BUILD
    return (
        <div className={"w-full flex justify-between items-start"}>
            <div className={"bg-backgroundLight3 w-[80px] min-w-[104px] flex flex-col justify-start items-start rounded-[8px] px-12 py-6"}>
                <GamesTimeLeft gameTimeLeft={gameTimeLeft} />
                <GamesCurrentScore currentScore={currentScore} />
            </div>
            <GamesLeaveButton />
        </div>
    );
}

// EXPORT
export default GamesTopBar