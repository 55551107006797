
function RelativeSumGameAnswer({sign, answer}) {
    // Used in RelativeSumGameAnsweringBlock to display
    // current user answer made of sign and number

    // BUILD
    return (
        <div className={"h-[72px] min-w-[144px] px-16 flex items-center border-[2px] border-primary rounded-[8px]"}>
            <div className={"min-w-[32px] h-full flex justify-center items-center"}>
                <span className={"text-purple font-bold text-[48px] mb-9"}>
                    {sign}
                </span>
            </div>
            <div className={"grow h-full flex justify-center items-center"}>
                <span className={"text-white font-bold text-[48px] pb-2"}>
                    {answer}
                </span>
            </div>
        </div>
    );
}

// EXPORT
export default RelativeSumGameAnswer