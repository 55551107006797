import GamesScreenTabletTopBar from "../components/bars/gamesScreen/GamesScreenTabletTopBar";
import GamesScreenDesktopTopBar from "../components/bars/gamesScreen/GamesScreenDesktopTopBar";
import GamesScreenLayout from "../components/layouts/gamesScreen/GamesScreenLayout";
import GamesScreenContentLayout from "../components/layouts/gamesScreen/GamesScreenContentLayout";
import GamesScreenContent from "../components/others/gamesScreen/GamesScreenContent";

function GamesScreen() {
    // Used in index.js to handle Games related content

    // BUILD
    return (
        <div className={"animate-appearSlow overflow-auto w-full h-[100svh] bg-backgroundLight3 flex flex-col justify-start items-center"}>
            <GamesScreenTabletTopBar />
            <GamesScreenDesktopTopBar />
            <GamesScreenLayout>
                <GamesScreenContentLayout>
                    <GamesScreenContent />
                </GamesScreenContentLayout>
            </GamesScreenLayout>
        </div>
    );
}

// EXPORT
export default GamesScreen