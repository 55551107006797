import GamesList from "../../lists/gamesScreen/GamesList";


function GamesScreenContent() {
    // Used in GamesScreen to manage Games related content

    // BUILD
    return (
        <div className={"animate-appearSlow overflow-auto w-full grow flex flex-col justify-start items-center"}>
            <GamesList />
        </div>
    );

}

// EXPORT
export default GamesScreenContent