// Used through the app to enable icon choice auto-completion
// and thus reduce typo

// PROPERTIES
// ---- System icons
export const kSystemIcons = {
    about: "history_edu",
    balance: "account_balance",
    assignment: "assignment",
    bolt: "bolt",
    cancel: "cancel",
    check: "check",
    chevronRight: "chevron_right",
    convention: "assignment",
    error: "error",
    function: "functions",
    games: "gamepad",
    heart: "favorite",
    help: 'help',
    level: "school",
    lock: "lock",
    logout: "logout",
    menu: "menu",
    play: "play_arrow",
    property: "gavel",
    psychology: "psychology",
    question: "question_mark",
    smartphone: "smartphone",
    steps: "more_vert",
    support: "support",
    task: "adjust",
    timer: "timer",
    title: "title",
    theme: "bookmarks",
    zoomIn: "zoom_in"
}