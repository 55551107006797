import GamesTimerIcon from "../../icons/games/GamesTimerIcon";

function GamesTimeLeft({gameTimeLeft}) {
    // Used in

    // BUILD
    return (
        <div className={"flex space-x-4 justify-start items-center font-normal"}>
            <GamesTimerIcon />
            <span className={"text-greyLight7 text-[18px]"}>
                {":"}
            </span>
            <span className={"font-medium text-secondaryLight1 text-[22px] pl-2"}>
                {gameTimeLeft + " s"}
            </span>
        </div>
    );
}

// EXPORT
export default GamesTimeLeft