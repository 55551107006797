import {SystemPrimaryCustomButton, SystemSecondaryCustomButton} from "../../buttons/SystemCustomButton";
import {kButtonStyles} from "../../../constants/styles";
import {kSystemTexts} from "../../../constants/texts";
import {routes} from "../../../constants/routes";

function GamesResultsBottomBar({onRetryPressed}) {
    // Used in Games in results to offer user the choice
    // between going back to GamesScreen or retry the
    // current game

    // BUILD
    return (
        <div className={"w-full flex space-x-16 mt-24"}>
            <SystemSecondaryCustomButton
                label={kSystemTexts.back}
                isDisabled={false}
                isLoading={false}
                isFullWidth={true}
                sizeStyle={kButtonStyles.largeButtonStyle}
                internalLink={routes.games} />
            <SystemPrimaryCustomButton
                label={kSystemTexts.retry}
                isDisabled={false}
                isLoading={false}
                isFullWidth={true}
                sizeStyle={kButtonStyles.largeButtonStyle}
                onButtonClick={onRetryPressed}
            />
        </div>
    );
}

// EXPORT
export default GamesResultsBottomBar