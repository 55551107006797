import HomeNavigationItem from "../../items/homeScreen/HomeNavigationItem";
import {kHomeTexts} from "../../../constants/texts";
import {kSystemIcons} from "../../../constants/icons";
import {routes} from "../../../constants/routes";

function HomeDrawerMainNavigation() {
    // Used in HomeDrawer to display main navigation items

    // PROPERTIES
    const mainNavigationItems = [
        {
            name: kHomeTexts.navKnowledges,
            icon: kSystemIcons.psychology,
            route: routes.knowledges,
        },
        {
            name: kHomeTexts.navGames,
            icon: kSystemIcons.games,
            route: routes.games,
        }
    ]

    // BUILD
    return (
        <div className={"w-full flex flex-col space-y-12 px-12 my-16"}>
            {mainNavigationItems.map((item, index) => (
                <HomeNavigationItem key={index}
                                    item={item} />
            ))}
        </div>
    );
}

// EXPORT
export default HomeDrawerMainNavigation