import {isNullOrUndefined} from "../../../services/Validator";

function GamesScreenLayout({children}) {
    // Used in KnowledgesContent to frame Knowledges
    // map

    // BUILD
    if (isNullOrUndefined(children))
        return null
    return (
        <div className={"overflow-auto bg-backgroundLight3 w-full grow flex flex-col justify-start items-center"}>
            {children}
        </div>
    );
}

// EXPORT
export default GamesScreenLayout