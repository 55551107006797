
function GamesOverAppreciationText({gamesOverText}) {
    // Used in Games result to display final appreciation
    // message about game result

    // BUILD
    return (
        <div className={"w-full flex justify-center items-center"}>
            <span className={"font-bold text-[26px] text-white"}>
                {gamesOverText}
            </span>
        </div>
    );
}

// EXPORT
export default GamesOverAppreciationText