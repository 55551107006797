import {useCallback, useEffect, useRef, useState} from "react";
import {kGamesNumbers} from "../../constants/numbers";
import GamesOverlay from "../../components/others/games/GamesOverlay";
import MultiplicationGameContent from "../../components/others/multiplicationGameScreen/MultiplicationGameContent";
import {kSystemTexts} from "../../constants/texts";
import {isNumberKeyDisabled} from "../../controllers/multiplicationGameScreen/MultiplicationKeyItemController";
import MultiplicationResults from "../../components/others/multiplicationGameScreen/MultiplicationResults";
import {flushSync} from "react-dom";

function MultiplicationGameScreen() {
    // Used in GamesScreen to handle multiplication game
    // logic

    // PROPERTIES
    // ---- Overlay
    const overlayTimerRef = useRef(null);
    const [displayOverlay, setDisplayOverlay] = useState(true)
    // ---- Game
    const gameTimerRef = useRef(null);
    const [gameTimeLeft, setGameTimeLeft] = useState(kGamesNumbers.gameTimeLeft);
    const [gameState, setGameState] = useState({
        multiplication: null,
        userAnswer: "",
        score: 0,
        isGameOver: false,
        isLost: false
    })

    // CALLBACKS
    // ---- Start game timer
    const startGameTimer = useCallback(() => {
        // Start timer
        gameTimerRef.current = setInterval(() => {
            // Update gameTimeLeft
            setGameTimeLeft((prevValue) => {
                if (prevValue <= 1) {
                    // End game
                    setGameState((prev) => ({
                        ...prev,
                        isLost: prev.score === 0,
                        isGameOver: true,
                    }))
                }
                return prevValue - 1
            })
        }, 1000);
    }, [])

    // ---- Start overlay timer
    const startOverlayTimer = useCallback(() => {
        // Start overlay timer
        overlayTimerRef.current = setTimeout(() => {
            setDisplayOverlay(false)
            startGameTimer()
        }, kGamesNumbers.overlayTimer * 1000)
    }, [startGameTimer])

    // HOOKS
    // ---- At rendering : Start overlay timer
    useEffect(() => {
        // Clean potential previous timer
        if (overlayTimerRef.current) {
            clearInterval(overlayTimerRef.current);
        }
        // Clean potential previous timer
        if (gameTimerRef.current) {
            clearInterval(gameTimerRef.current);
        }
        // Set multiplication
        const firstMultiplication = generateMultiplication()
        setGameState((prev) => ({
            ...prev,
            multiplication: firstMultiplication,
        }));
        // setMultiplication(firstMultiplication)
        // Start overlay timer
        startOverlayTimer()
        // Nettoyer le timer au démontage du composant
        return () => {
            if (overlayTimerRef.current)
                clearTimeout(overlayTimerRef.current)
            if (gameTimerRef.current)
                clearInterval(gameTimerRef.current)
        }
    }, [startOverlayTimer]);

    // BUILD
    return (
        <div className={"select-none relative overflow-auto bg-backgroundLight3 w-full h-[100svh] flex flex-col justify-start items-center"}>
            <GamesOverlay displayOverlay={displayOverlay} />
            {gameState.isGameOver
                ? <MultiplicationResults
                    isLost={gameState.isLost}
                    finalScore={gameState.score}
                    lastMultiplication={gameState.multiplication?.toCompleteText}
                    lastAnswer={gameState.userAnswer}
                    onRetryPressed={restartGame} />
                : <MultiplicationGameContent
                    currentQuestion={gameState.multiplication?.toQuestion}
                    currentScore={gameState.score}
                    userAnswer={gameState.userAnswer}
                    gameTimeLeft={gameTimeLeft}
                    onKeyPressed={onKeyPressed} /> }
        </div>
    );

    // METHODS
    // ---- LOGIC : Generate multiplication question
    function generateMultiplication() {
        const factorOne = Math.floor(Math.random() * 10) + 1;
        const factorTwo = Math.floor(Math.random() * 10) + 1;
        const result = factorOne * factorTwo
        return {
            factorOne: factorOne,
            factorTwo: factorTwo,
            result: result,
            toQuestion: factorOne + " x " + factorTwo + " = ...",
            toCompleteText: factorOne + " x " + factorTwo + " = " + result
        };
    }

    // ---- LOGIC : On item key pressed
    function onKeyPressed(item, userAnswer) {
        if (item === kSystemTexts.validate) {
            if (userAnswer.length !== 0) {
                // Case answer is not empty
                if (userAnswer === gameState.multiplication.result.toString()) {
                    // Case answer is correct
                    const newMultiplication = generateMultiplication()
                    setGameState((prev) => ({
                        ...prev,
                        multiplication: newMultiplication,
                        userAnswer: "",
                        score: prev.score + 1
                    }));
                } else {
                    // Case answer is wrong
                    setGameState((prev) => ({
                        ...prev,
                        isLost: true,
                        isGameOver: true
                    }));
                    // Clear timer
                    clearInterval(gameTimerRef.current)
                }
            }
        } else if (item === kSystemTexts.erase) {
            // Delete last given value
            setGameState((prev) => ({
                ...prev,
                userAnswer: prev.userAnswer.slice(0, -1),
            }));
        } else {
            if (!isNumberKeyDisabled(userAnswer)) {
                setGameState((prev) => ({
                    ...prev,
                    userAnswer: prev.userAnswer + item,
                }));
            }
        }
    }

    // ---- LOGIC : Restart game
    function restartGame() {
        // Clean potential previous timer
        if (overlayTimerRef.current) {
            clearInterval(overlayTimerRef.current);
        }
        // Clean potential previous timer
        if (gameTimerRef.current) {
            clearInterval(gameTimerRef.current);
        }
        // Restart timers and overlay with immediate effect
        flushSync(() => {
            setGameTimeLeft(kGamesNumbers.gameTimeLeft)
            setDisplayOverlay(true)
        });
        // Reset game state
        const firstMultiplication = generateMultiplication()
        setGameState((prev) => ({
            ...prev,
            multiplication: firstMultiplication,
            userAnswer: "",
            score: 0,
            isGameOver: false,
            isLost: false
        }));
        // Start
        startOverlayTimer()
    }
}


// EXPORT
export default MultiplicationGameScreen