import GamesQuestionBlock from "../games/GamesQuestionBlock";
import MultiplicationGameAnswer from "./MultiplicationGameAnswer";

function MultiplicationGameAnsweringBlock({currentQuestion, userAnswer}) {
    // Used in

    // BUILD
    return (
        <div className={"grow flex flex-col space-y-8 justify-center items-center pb-24"}>
            <GamesQuestionBlock
                currentQuestion={currentQuestion} />
            <MultiplicationGameAnswer
                answer={userAnswer} />
        </div>
    );
}

// EXPORT
export default MultiplicationGameAnsweringBlock