import GamesResultsLayout from "../../layouts/games/GamesResultsLayout";
import {kGamesNumbers} from "../../../constants/numbers";
import GamesLooseResultContent from "../games/GamesLooseResultContent";
import GamesRecordResultContent from "../games/GamesRecordResultContent";
import GamesSuccessResultContent from "../games/GamesSuccessResultContent";
import GamesResultsBottomBar from "../../bars/games/GamesResultsBottomBar";
import {kMultiplicationLists} from "../../../constants/lists";

function MultiplicationResults({isLost, finalScore, lastMultiplication, lastAnswer, onRetryPressed}) {
    // Used in MultiplicationGameScreen to display loose, success of
    // record related content

    // BUILD
    return (
        <div className={"animate-appearSlow bg-backgroundLight3 w-full h-full flex flex-col justify-center items-center"}>
            <GamesResultsLayout>
                {isLost
                    ? <GamesLooseResultContent
                        lastQuestion={lastMultiplication}
                        finalScore={finalScore}
                        lastAnswer={lastAnswer}
                        advicesList={kMultiplicationLists.looseAdvices} />
                    : finalScore > kGamesNumbers.multiplicationRecord
                        ? <GamesRecordResultContent finalScore={finalScore}
                                                    advicesList={kMultiplicationLists.recordAdvices} />
                        : <GamesSuccessResultContent finalScore={finalScore} /> }
                <GamesResultsBottomBar
                    onRetryPressed={onRetryPressed} />
            </GamesResultsLayout>
        </div>
    );
}

// EXPORT
export default MultiplicationResults