import {isNullOrUndefined} from "../../../services/Validator";

function GamesContentLayout({children}) {
    // Used in Games to frame content

    // BUILD
    if (isNullOrUndefined(children))
        return null
    return (
        <div className={"bg-backgroundLight5 w-[560px] md:w-[640px] lg:w-[720px] h-full flex flex-col justify-start items-center px-24 pb-24 pt-16"}>
            {children}
        </div>
    );
}

// EXPORT
export default GamesContentLayout