import {isNullOrUndefined} from "../../../services/Validator";

function GamesItemRuleText({rule}) {
    // Used in GamesScreenItemInfo to display one single Game item
    // rule

    // BUILD
    if (isNullOrUndefined(rule))
        return null;
    return (
        <div className={"text-[16px] font-medium text-greyLight9 flex space-x-8 items-start justify-start"}>
            <div className={"h-[8px] min-h-[8px] w-[8px] min-w-[8px] bg-greyLight9 rounded-full mt-8"}></div>
            <span>
                {rule}
            </span>
        </div>
    );
}

// EXPORT
export default GamesItemRuleText