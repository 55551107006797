import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {routes} from "../../../constants/routes";
import GamesLeaveText from "../../texts/games/GamesLeaveText";
import GamesLeaveIcon from "../../icons/games/GamesLeaveIcon";

function GamesLeaveButton() {
    // Used in GamesTopBar to display a leave game
    // button made of icon and text

    // PROPERTIES
    let navigate = useNavigate();
    const [isHovered, setIsHovered] = useState(false)

    // BUILD
    return (
        <div className={`cursor-pointer flex space-x-4 justify-start items-start ${isHovered ? "text-greyLight9" : "text-greyLight5"} pt-6`}
             onClick={() => navigate(routes.games)}
             onMouseEnter={() => setIsHovered(true)}
             onMouseLeave={() => setIsHovered(false)} >
            <GamesLeaveIcon />
            <GamesLeaveText />
        </div>
    );
}

// EXPORT
export default GamesLeaveButton