import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import FlashScreen from "./screens/FlashScreen";
import AboutScreen from "./screens/AboutScreen";
import LegalsScreen from "./screens/LegalsScreen";
import ErrorScreen from "./screens/ErrorScreen";
import MetaLayoutScreen from "./screens/MetaLayoutScreen";
import HomeScreen from "./screens/HomeScreen";
import {kLegalContentKeys} from "./constants/keys";
import {routes} from "./constants/routes";
import PreviewScreen from "./screens/PreviewScreen";
import ThemesScreen from "./screens/ThemesScreen";
import KnowledgesScreen from "./screens/KnowledgesScreen";
import GamesScreen from "./screens/GamesScreen";
import MultiplicationGameScreen from "./screens/gamesScreens/MultiplicationGameScreen";
import RelativeSumGameScreen from "./screens/gamesScreens/RelativeSumGameScreen";

// Router
const router = createBrowserRouter([
    {
        path: routes.home,
        element: <MetaLayoutScreen/>,
        errorElement: <ErrorScreen />,
        children: [
            // ---- Main navigation
            { path: routes.home, element: <HomeScreen /> },
            { path: routes.flash, element: <FlashScreen /> },
            { path: routes.knowledges, element: <KnowledgesScreen /> },
            { path: routes.games, element: <GamesScreen />},
            // ---- ---- Games
            {path: routes.multiplicationGame, element: <MultiplicationGameScreen />},
            {path: routes.relativeSumGame, element: <RelativeSumGameScreen />},
            // ---- Side navigation
            { path: routes.about, element: <AboutScreen /> },
            { path: routes.legals, element: <LegalsScreen legalContentKey={kLegalContentKeys.legalMentions} /> },
            { path: routes.privacy, element: <LegalsScreen legalContentKey={kLegalContentKeys.privacyPolicy} /> },
            { path: routes.themes, element: <ThemesScreen /> }
        ],
    },
    {
        path: routes.preview,
        element: <PreviewScreen />
    }
], {
    future: {
        v7_relativeSplatPath: true, // Enables relative paths in nested routes
        v7_fetcherPersist: true,   // Retains fetcher state during navigation
        v7_normalizeFormMethod: true, // Normalizes form methods (e.g., POST or GET)
        v7_partialHydration: true, // Supports partial hydration for server-side rendering
        v7_skipActionErrorRevalidation: true, // Prevents revalidation when action errors occur
    },
});

// DOM
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <RouterProvider future={{ v7_startTransition: true }}
                      router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
