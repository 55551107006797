
// ---- Games
export const kGamesNumbers = {
    gameTimeLeft: 40,
    multiplicationAnswerMax: 3,
    multiplicationRecord: 1,
    overlayTimer: 3,
    relativeSumAnswerMax: 2,
    relativeSumRecord: 4,
}

// ---- SYSTEM
