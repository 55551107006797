
function GamesQuestionBlock({currentQuestion}) {
    // Used in

    // BUILD
    return (
        <div className={"flex justify-center items-center"}>
            <span className={"font-bold text-[32px] text-white"}>
                {currentQuestion}
            </span>
        </div>
    );
}

// EXPORT
export default GamesQuestionBlock