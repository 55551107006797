import GamesItemRuleText from "../../texts/gamesScreen/GamesItemRuleText";

function GamesScreenItemInfo({gameName, gameRules}) {
    // Used in GamesItem to display single Game
    // name and goal

    // BUILD
    return (
        <div className={"grow h-full flex flex-col justify-start items-start space-y-8 px-16 pt-8 pb-12"}>
            <span className={"text-[18px] font-bold text-white"}>
                {gameName}
            </span>
            <div className={"w-full grow flex flex-col justify-between pl-2"}>
                {gameRules.map((rule, index) => {
                    return <GamesItemRuleText key={index}
                                              rule={rule} />
                })}
            </div>
        </div>
    );
}

// EXPORT
export default GamesScreenItemInfo