import {kSystemIcons} from "../../../constants/icons";

function GamesLeaveIcon() {
    // Used in GamesLeaveButton to display cancel
    // icon for leaving the game before the end

    // BUILD
    return (
        <div className={"pt-2"}>
            <i className={"material-icons text-[20px]"}>
                {kSystemIcons.cancel}
            </i>
        </div>
    );
}

// EXPORT
export default GamesLeaveIcon