import {isNullOrUndefined} from "../../../services/Validator";

function GamesResultsContentLayout({children}) {
    // Used in Games to frame results content

    // BUILD
    if (isNullOrUndefined(children))
        return null
    return (
        <div className={"bg-backgroundLight5 shadow-[inset_0px_1px_2px_rgba(82,96,120,1)] w-full flex flex-col justify-start items-center rounded-[24px] px-24 pt-16 pb-24"}>
            {children}
        </div>
    );
}

// EXPORT
export default GamesResultsContentLayout