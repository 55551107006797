import {isArrayNullUndefinedOrEmpty} from "../../../services/Validator";
import GamesAdviceBulletItem from "../../items/games/GamesAdviceBulletItem";

function GamesAdvicesList({advicesList}) {
    // Used in

    // BUILD
    if (isArrayNullUndefinedOrEmpty(advicesList))
        return null
    return (
        <ul className="w-full flex flex-col justify-start items-start space-y-4 mt-8">
            {advicesList.map((item, index) => (
                <GamesAdviceBulletItem
                    key={index}
                    index={index}
                    item={item} />
            ))}
        </ul>
    );
}

// EXPORT
export default GamesAdvicesList