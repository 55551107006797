
function GamesWrongAnswerText({lastAnswer, finalScore}) {
    // Used in GamesLooseContentResult to display last user answer
    // that he will compare with given answer

    // BUILD
    if (finalScore <= 0)
        return null
    return (
        <div className={"flex space-x-6 justify-center items-center text-[20px] font-medium"}>
            <span className={"text-greyLight9"}>
                {"et non"}
            </span>
            <span className={"text-secondaryLight3"}>
                {lastAnswer}
            </span>
        </div>
    );
}

// EXPORT
export default GamesWrongAnswerText