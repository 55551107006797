import {useCallback, useEffect, useRef, useState} from "react";
import {kGamesNumbers} from "../../constants/numbers";
import GamesOverlay from "../../components/others/games/GamesOverlay";
import {kGamesTexts, kSystemTexts} from "../../constants/texts";
import {flushSync} from "react-dom";
import RelativeSumGameContent from "../../components/others/relativeSumGameScreen/RelativeSumGameContent";
import RelativeSumResults from "../../components/others/relativeSumGameScreen/RelativeSumResults";
import {isNumberKeyDisabled} from "../../controllers/relativeSumGameScreen/RelativeSumKeyItemController";
import {
    compareRelativeSumResult,
    relativeNumberToStringWithParenthesis,
    relativeSumResultFromNumberToString
} from "../../controllers/relativeSumGameScreen/RelativeSumGameScreenController";

function RelativeSumGameScreen() {
    // Used in GamesScreen to handle multiplication game
    // logic

    // PROPERTIES
    // ---- Overlay
    const overlayTimerRef = useRef(null);
    const [displayOverlay, setDisplayOverlay] = useState(true)
    // ---- Game
    const gameTimerRef = useRef(null);
    const [gameTimeLeft, setGameTimeLeft] = useState(kGamesNumbers.gameTimeLeft);
    const [gameState, setGameState] = useState({
        relativeSum: null,
        userAnswer: "",
        userSign: "+",
        score: 0,
        isGameOver: false,
        isLost: false
    })

    // CALLBACKS
    // ---- Start game timer
    const startGameTimer = useCallback(() => {
        // Start timer
        gameTimerRef.current = setInterval(() => {
            // Update gameTimeLeft
            setGameTimeLeft((prevValue) => {
                if (prevValue <= 1) {
                    // End game
                    setGameState((prev) => ({
                        ...prev,
                        isLost: prev.score === 0,
                        isGameOver: true,
                    }))
                }
                return prevValue - 1
            })
        }, 1000);
    }, [])

    // ---- Start overlay timer
    const startOverlayTimer = useCallback(() => {
        // Start overlay timer
        overlayTimerRef.current = setTimeout(() => {
            setDisplayOverlay(false)
            startGameTimer()
        }, kGamesNumbers.overlayTimer * 1000)
    }, [startGameTimer])

    // HOOKS
    // ---- At rendering : Start overlay timer
    useEffect(() => {
        // Clean potential previous timer
        if (overlayTimerRef.current) {
            clearInterval(overlayTimerRef.current);
        }
        // Clean potential previous timer
        if (gameTimerRef.current) {
            clearInterval(gameTimerRef.current);
        }
        // Set multiplication
        const firstRelativeSum = generateRelativeSum()
        setGameState((prev) => ({
            ...prev,
            relativeSum: firstRelativeSum,
        }));
        // Start overlay timer
        startOverlayTimer()
        // Nettoyer le timer au démontage du composant
        return () => {
            if (overlayTimerRef.current)
                clearTimeout(overlayTimerRef.current)
            if (gameTimerRef.current)
                clearInterval(gameTimerRef.current)
        }
    }, [startOverlayTimer]);

    // BUILD
    return (
        <div className={"select-none relative overflow-auto bg-backgroundLight3 w-full h-[100svh] flex flex-col justify-start items-center"}>
            <GamesOverlay displayOverlay={displayOverlay} />
            {gameState.isGameOver
                ? <RelativeSumResults
                    isLost={gameState.isLost}
                    finalScore={gameState.score}
                    lastRelativeSum={gameState.relativeSum?.toCompleteText}
                    lastAnswer={gameState.userAnswer}
                    onRetryPressed={restartGame} />
                : <RelativeSumGameContent
                    currentQuestion={gameState.relativeSum?.toQuestion}
                    currentScore={gameState.score}
                    userSign={gameState.userSign}
                    userAnswer={gameState.userAnswer}
                    gameTimeLeft={gameTimeLeft}
                    onKeyPressed={onKeyPressed} /> }
        </div>
    );

    // METHODS
    // ---- LOGIC : Generate relative sum question
    function generateRelativeSum() {
        // Prepare
        const relativeOne = Math.floor(Math.random() * 41) - 20;
        const relativeTwo = Math.floor(Math.random() * 41) - 20;
        const result = relativeOne + relativeTwo

        // Return
        return {
            relativeOne: relativeOne,
            factorTwo: relativeTwo,
            result: result,
            toQuestion: relativeNumberToStringWithParenthesis(relativeOne) + " + " + relativeNumberToStringWithParenthesis(relativeTwo) + " = ...",
            toCompleteText: relativeNumberToStringWithParenthesis(relativeOne) + " + " + relativeNumberToStringWithParenthesis(relativeTwo) + " = " + relativeSumResultFromNumberToString(result)
        };
    }

    // ---- LOGIC : On item key pressed
    function onKeyPressed(item, userSign, userAnswer) {
        if (item === kSystemTexts.validate) {
            // Case : key item is validate button
            if (userAnswer.length !== 0) {
                // Case answer is not empty
                if (compareRelativeSumResult(userSign, userAnswer, gameState.relativeSum.result)) {
                    // Case answer is correct
                    const newRelativeSum = generateRelativeSum()
                    setGameState((prev) => ({
                        ...prev,
                        relativeSum: newRelativeSum,
                        userAnswer: "",
                        score: prev.score + 1
                    }));
                } else {
                    // Case answer is wrong => end game and clear timer
                    setGameState((prev) => ({
                        ...prev,
                        isLost: true,
                        isGameOver: true
                    }));
                    clearInterval(gameTimerRef.current)
                }
            }
        } else if (item === kSystemTexts.erase) {
            // Case : key item is erase button => Delete last given value
            setGameState((prev) => ({
                ...prev,
                userAnswer: prev.userAnswer.slice(0, -1),
            }));
        } else if (item === kGamesTexts.plusMinus) {
            // Case : key item is plus minus button
            setGameState((prev) => ({
                ...prev,
                userSign: prev.userSign === "+" ? "-" : "+"
            }))
        } else {
            // Case : key item is a number from 0 to 9
            if (!isNumberKeyDisabled(userAnswer)) {
                setGameState((prev) => ({
                    ...prev,
                    userAnswer: prev.userAnswer + item,
                }));
            }
        }
    }

    // ---- LOGIC : Restart game
    function restartGame() {
        // Clean potential previous timer
        if (overlayTimerRef.current) {
            clearInterval(overlayTimerRef.current);
        }
        // Clean potential previous timer
        if (gameTimerRef.current) {
            clearInterval(gameTimerRef.current);
        }
        // Restart timers and overlay with immediate effect
        flushSync(() => {
            setGameTimeLeft(kGamesNumbers.gameTimeLeft)
            setDisplayOverlay(true)
        });
        // Reset game state
        const firstRelativeSum = generateRelativeSum()
        setGameState((prev) => ({
            ...prev,
            relativeSum: firstRelativeSum,
            userAnswer: "",
            userSign: "+",
            score: 0,
            isGameOver: false,
            isLost: false
        }));
        // Start
        startOverlayTimer()
    }
}


// EXPORT
export default RelativeSumGameScreen