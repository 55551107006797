
function MultiplicationGameAnswer({answer}) {
    // Used in

    // BUILD
    return (
        <div className={"h-[72px] w-[160px] flex justify-center items-center border-[2px] border-primary rounded-[8px]"}>
            <span className={"text-white font-bold text-[48px]"}>
                {answer}
            </span>
        </div>
    );
}

// EXPORT
export default MultiplicationGameAnswer