
// ---- Format relative number to string with parenthesis
export function relativeNumberToStringWithParenthesis(relativeNumber) {
    if (relativeNumber > 0) {
        return `(+${relativeNumber})`
    } else {
        return "(" + relativeNumber + ")"
    }
}

// ---- Format relativeSumResult number into string with potential sign
export function relativeSumResultFromNumberToString(relativeSumResult) {
    if (relativeSumResult > 0) {
        // Case : relativeSumResult is positive
        return `+${relativeSumResult}`
    } else if (relativeSumResult === 0) {
        // Case : relativeSumResult is zero
        return "0"
    } else {
        // Case : relativeSumResult is negative
        return relativeSumResult.toString()
    }
}

// ---- Compare userAnswer with current relativeSum result
export function compareRelativeSumResult(userSign, userAnswer, relativeSumResult) {
    if (typeof userSign !== "string" || typeof userAnswer !== "string" || typeof relativeSumResult !== "number")
        return false
    if (userAnswer === "0")
        return userAnswer === relativeSumResultFromNumberToString(relativeSumResult)
    return (userSign + userAnswer) === relativeSumResultFromNumberToString(relativeSumResult)
}