import RelativeSumKeyItem from "../../items/relativeSumGameScreen/RelativeSumKeyItem";
import {kGamesTexts, kSystemTexts} from "../../../constants/texts";

function RelativeSumGameKeyboard({userSign, userAnswer, onKeyPressed}) {
    // Used in RelativeSumGameContent to display
    // specific keyboard fo this game

    // CONSTANTS
    const topKeys = [
        '1', '2', '3', '4',
        '5', '6', '7', '8',
    ];

    // BUILD
    return (
        <div className={"w-full flex flex-col space-y-16"}>
            <div className="w-full grid grid-cols-4 gap-[16px]">
                {topKeys.map((item, index) => (
                    <RelativeSumKeyItem key={index}
                                        item={item}
                                        userSign={userSign}
                                        userAnswer={userAnswer}
                                        onKeyPressed={onKeyPressed} />
                ))}
            </div>
            <div className={"w-full grid grid-cols-2 gap-[16px]"}>
                <div className={"flex-1 grid grid-cols-2 gap-[16px]"}>
                    <RelativeSumKeyItem item={"9"}
                                        userSign={userSign}
                                        userAnswer={userAnswer}
                                        onKeyPressed={onKeyPressed} />
                    <RelativeSumKeyItem item={"0"}
                                        userSign={userSign}
                                        userAnswer={userAnswer}
                                        onKeyPressed={onKeyPressed} />
                </div>
                <div className={"flex-1"}>
                    <RelativeSumKeyItem item={kGamesTexts.plusMinus}
                                        userSign={userSign}
                                        userAnswer={userAnswer}
                                        onKeyPressed={onKeyPressed} />
                </div>
            </div>
            <div className={"w-full grid grid-cols-2 gap-[16px]"}>
                <RelativeSumKeyItem item={kSystemTexts.erase}
                                    userSign={userSign}
                                    userAnswer={userAnswer}
                                    onKeyPressed={onKeyPressed} />
                <RelativeSumKeyItem item={kSystemTexts.validate}
                                    userSign={userSign}
                                    userAnswer={userAnswer}
                                    onKeyPressed={onKeyPressed} />
            </div>
        </div>

    );
}

// EXPORT
export default RelativeSumGameKeyboard