
function GamesOverScoreCounterText({finalScore}) {
    // Used in both GamesRecordResultContent and
    // GamesSuccessResultContent to display user final score

    // BUILD
    return (
        <div className={"w-full flex flex-col space-y-2 justify-start items-center mt-12"}>
            <span className={"text-[20px] font-medium text-greyLight9 leading-none"}>
                {"avec"}
            </span>
            <div className={"flex space-x-8 justify-center items-end text-primaryLight1 leading-tight"}>
                <span className={"text-[28px] font-semibold"}>
                    {finalScore.toString()}
                </span>
                <span className={"text-[22px] font-semibold pb-1"}>
                    {finalScore > 1 ? "bonnes réponses" : "bonne réponse"}
                </span>
            </div>
        </div>
    );
}

// EXPORT
export default GamesOverScoreCounterText