
function GamesAdviceBulletItem({index, item}) {
    // Used in GamesAdvicesList to display one single bullet item
    // with fadeIn animation

    // BUILD
    return (
        <li key={index}
            className={`opacity-0 transform translate-y-5 animate-fadeInUp`}
            style={{ animationDelay: `${index * 1}s` }}
            aria-hidden={true} >
                <div className={"flex space-x-10 justify-start items-start text-white"}>
                    <div className={"h-[8px] min-h-[8px] w-[8px] min-w-[8px] bg-white rounded-full mt-9"}></div>
                    <span className={"text-[16px] font-semibold"}>
                        {item}
                    </span>
                </div>
        </li>
    );
}

// EXPORT
export default GamesAdviceBulletItem