import {
    renderKeyItemColor,
    renderKeyItemStyle
} from "../../../controllers/multiplicationGameScreen/MultiplicationKeyItemController";
import {useState} from "react";

function MultiplicationKeyItem({item, userAnswer, onKeyPressed}) {
    // Used in MultiplicationGameKeyboard to display one single
    // key for the specific multiplication game

    // PROPERTIES
    const [isHovered, setIsHovered] = useState(false)

    // BUILD
    return (
        <div className={`cursor-pointer ${renderKeyItemStyle(item, userAnswer, isHovered)} h-[48px] min-h-[56px] flex justify-center items-center rounded-[8px]`}
             onMouseEnter={() => setIsHovered(true)}
             onMouseLeave={() => setIsHovered(false)}
             onClick={() => {onKeyPressed(item, userAnswer)}} >
            <span className={`font-medium ${renderKeyItemColor(item)}`}>
                {item}
            </span>
        </div>
    );
}

// EXPORT
export default MultiplicationKeyItem