
function GamesAnsweredQuestionText({lastQuestion}) {
    // Used in Games result contents to display last answered question
    // text

    // BUILD
    return (
        <div className={"mt-4"}>
            <span className={"font-semibold text-[26px] text-secondaryLight3"}>
                {lastQuestion}
            </span>
        </div>
    );
}

// EXPORT
export default GamesAnsweredQuestionText