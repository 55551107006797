import {kGamesScreenImages} from "./images";
import {routes} from "./routes";

// ---- aboutScreen
export const kMethodQuestion = {
    "id": 0,
    "query": "12,34 x 100 = ...",
    "isVerified": true,
    "options": [
        {
            "id": 0,
            "proposal": "1 234",
            "explanation": "En multipliant 12,34 par 100 :\n1 dizaine devient 1 millier\n2 unités deviennent 2 centaines\n3 dixièmes deviennent 3 dizaines\n4 centièmes deviennent 4 unités\n12,34 x 100 = 1 234",
            "isCorrect": true,
        },
        {
            "id": 1,
            "proposal": "123,4",
            "explanation": "Attention, il s'agit d'une multiplication par 100 et non par 10.\nEn multipliant 12,34 par 100 :\n1 dizaine devient 1 millier\n2 unités deviennent 2 centaines\n3 dixièmes deviennent 3 dizaines\n4 centièmes deviennent 4 unités\n12,34 x 100 = 1 234",
            "isCorrect": false,
        },
        {
            "id": 2,
            "proposal": "12,3400",
            "explanation": "Attention, rajouter deux zéros derrière la virgule ne change pas la valeur du nombre de départ.\n\nEn multipliant 12,34 par 100 :\n1 dizaine devient 1 millier\n2 unités deviennent 2 centaines\n3 dixièmes deviennent 3 dizaines\n4 centièmes deviennent 4 unités\n12,34 x 100 = 1 234",
            "isCorrect": false,
        }
    ],
    "levelA": {
        "id": 14,
        "task": "Multiplier un nombre par 100",
        "knowledge": {
            "id": 15,
            "keyword": "Multiplier un nombre par 100",
            "definition": "Quand on multiplie un nombre par 100 on donne une valeur 100 fois plus grande à chacun de ses chiffres.\nLes dixièmes deviennent des dizaines, les unités deviennent des centaines, les dizaines deviennent des milliers…etc"
        },
        "grade": {
            "id": 7,
            "rank": 7,
            "name": "CM1",
            "shortDescription": "La classe de CM2 correspond au deuxième niveau du cycle 3, la série sera donc composée de questions de CM1 et de CM2",
            "cycle": {
                "id": 3,
                "title": "Cycle 3",
                "shortDescription": "Le cycle 3, ou cycle de consolidation, comprend le cours moyen première année (CM1) et le cours moyen deuxième année (CM2) ; le cycle de consolidation se poursuit au collège, en classe de sixième."
            }
        },
        "subtheme": {
            "id": 0,
            "name": "Calcul mental",
            "shortDescription": null,
            "theme": {
                "id": 0,
                "identifier": 0,
                "name": "Nombres et calcul",
                "shortDescription": "Dans ce thème, tu vas pratiquer différents types de calculs comme le calcul posé, le calcul littéral, le calcul mental et le calcul réfléchi. Et ce, avec de nouveaux types de nombre et notamment les fractions et les nombres relatifs."
            }
        }
    }
}

// ---- gamesScreen
export const kGamesList = [
    {
        name: "Tables de multiplication",
        rules: [
            "Calcule le maximum de multiplication entre deux nombres compris entre 0 et 10",
            "Toute faute entraine la fin du jeu",
            "Temps imparti : 1 minute",
        ],
        backgroundImage: kGamesScreenImages.multiplicationBackground,
        route: routes.multiplicationGame
    },
    {
        name: "Addition de nombres relatifs",
        rules: [
            "Calcule le maximum d'additions entre deux nombres relatifs compris entre -20 et +20",
            "Toute faute entraine la fin du jeu",
            "Temps imparti : 1 minute",
        ],
        backgroundImage: kGamesScreenImages.relativeSumBackground,
        route: routes.relativeSumGame
    }
]

// ---- SYSTEM
export const kSystemDontKnowOption = {
    proposal: "Je ne sais pas",
    isCorrect : null
}