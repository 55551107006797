import {kSystemIcons} from "../../../constants/icons";

function GamesTimerIcon() {
    // Used in GamesLeaveButton to display cancel
    // icon for leaving the game before the end

    // BUILD
    return (
        <div className={"pt-6"}>
            <i className={"material-icons text-[20px] text-greyLight7"}>
                {kSystemIcons.timer}
            </i>
        </div>
    );
}

// EXPORT
export default GamesTimerIcon