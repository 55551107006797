import {kGamesTexts} from "../../../constants/texts";

function GamesLeaveText() {
    // Used in GamesLeaveButton to display leave game text

    // BUILD
    return (
        <div>
            <span className={"font-medium text-[16px]"}>
                {kGamesTexts.leave}
            </span>
        </div>
    );
}

// EXPORT
export default GamesLeaveText