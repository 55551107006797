import {kSystemDontKnowOption} from "../../../../constants/objects";
import {isArrayNullUndefinedOrEmpty} from "../../../../services/Validator";
import QuestionsOptionItem from "../../../items/flashScreens/questionsStepScreen/QuestionsOptionItem";

function QuestionsOptionsList({options,
                               optionSelected,
                               setOptionSelected,
                               onDontKnowPress}) {
    // Used in QuestionsCard to display list of question
    // options and a default DontKnow option

    // BUILD
    if (isArrayNullUndefinedOrEmpty(options))
        return null
    return (
        <div className={"w-full h-[304px] min-h-[304px] flex flex-col space-y-16"}>
            {/** OPTIONS */}
            {options.map((option, index) => (
                <QuestionsOptionItem key={index}
                                     isLast={false}
                                     option={option}
                                     optionSelected={optionSelected}
                                     setOptionSelected={setOptionSelected}
                                     onDontKnowPress={onDontKnowPress} />
            ))}
            {/** DONT KNOW ITEM */}
            <QuestionsOptionItem isLast={true}
                                 option={kSystemDontKnowOption}
                                 optionSelected={optionSelected}
                                 setOptionSelected={setOptionSelected}
                                 onDontKnowPress={onDontKnowPress} />
        </div>
    );
}

// EXPORT
export default QuestionsOptionsList