import {kGamesTexts, kSystemTexts} from "../../constants/texts";
import {kGamesNumbers} from "../../constants/numbers";

// ---- Render key item style
export function renderKeyItemStyle(item, userAnswer, isHovered) {
    if (item === kSystemTexts.erase) {
        return isEraseKeyItemDisabled(userAnswer)
            ? 'bg-greyLight5'
            : isHovered
                ? 'bg-pinkLight1'
                : 'bg-pink'
    } else if (item === kSystemTexts.validate) {
        return isValidateKeyItemDisabled(userAnswer)
            ? 'bg-greyLight5'
            : isHovered
                ? 'bg-primaryLight2'
                : 'bg-primaryLight1'
    } else if (item === kGamesTexts.plusMinus) {
        return isHovered ? 'bg-purpleLight1' : 'bg-purple';
    } else {
        return isNumberKeyDisabled(userAnswer)
            ? 'bg-greyLight5'
            : isHovered
                ? 'bg-bearBodyWhiteLight1'
                : 'bg-bearBodyWhiteColor'
    }
}

export function renderKeyItemColor(item) {
    if (item === kSystemTexts.erase || item === kSystemTexts.validate) {
        return "text-white"
    } else {
        return 'text-greyLight1'
    }
}

// ---- Is erase key item disabled
export function isEraseKeyItemDisabled(userAnswer) {
    return userAnswer.length === 0
}

// ---- Is validate key item disabled
export function isValidateKeyItemDisabled(userAnswer) {
    return userAnswer.length === 0
}

// ---- Is number key item disabled
export function isNumberKeyDisabled(userAnswer) {
    return !(userAnswer.length < kGamesNumbers.relativeSumAnswerMax && userAnswer[0] !== "0")
}