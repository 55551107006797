import GamesItemBackgroundImage from "../../images/gamesScreen/GamesItemBackgroundImage";
import GamesScreenItemInfo from "../../others/gamesScreen/GamesScreenItemInfo";
import {useNavigate} from "react-router-dom";
import {useState} from "react";

function GamesItem({game}) {
    // Used in GamesList to display one single Games
    // item made of background image, name and goal

    // PROPERTIES
    const [isHovered, setIsHovered] = useState(false)
    let navigate = useNavigate();

    // BUILD
    return (
        <div className={`cursor-pointer overflow-clip w-full h-[160px] ${isHovered ? "bg-greyLight3" : "bg-backgroundLight5"} flex justify-start items-start rounded-[16px]`}
             onClick={() => {navigate(game.route)}}
             onMouseEnter={() => setIsHovered(true)}
             onMouseLeave={() => setIsHovered(false)} >
            <GamesItemBackgroundImage backgroundImage={game.backgroundImage} />
            <GamesScreenItemInfo gameName={game.name}
                                 gameRules={game.rules} />
        </div>
    );
}

// EXPORT
export default GamesItem