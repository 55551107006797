import Lottie from 'react-lottie-player'
import animationData from '../../../animations/games/countdownAnimation.json'

function GamesOverlay({displayOverlay}) {
    // Used in Games to display full screen countdown when
    // game is started

    // BUILD
    if (!displayOverlay)
        return null
    return (
        <div className={'absolute bg-black bg-opacity-80 w-full h-full flex justify-center items-center'}>
            <Lottie
                loop
                animationData={animationData}
                play
                style={{ width: 240, height: 240 }} />
        </div>
    );
}

// EXPORT
export default GamesOverlay