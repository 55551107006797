
// ---- Games
export const kGamesLists = {
    successAdvices: [
        "En math, on cherche le zéro faute, félicitations.",
        "Tu peux recommencer pour aller de plus en plus loin.",
        "En créant l'application, j'ai caché mon propre record, sauras-tu le battre\u00A0?",
    ]
}

// ---- multiplicationGameScreen
export const kMultiplicationLists = {
    looseAdvices: [
        "En math, on priorise la justesse avant la vitesse.",
        "N'hésite pas à vérifier tes calculs sur une feuille.",
        "N'oublie pas qu'une multiplication, c'est juste plusieurs additions répétées.\nPar exemple : 3 x 5 = 5 + 5 + 5",
    ],
    recordAdvices: [
        "Félicitations.",
        "Tu maitrises parfaitement les tables de multiplication.",
        "N'hésite pas à revenir si tu as besoin de travailler tes réflexes.",
    ],
}

// ---- relativeSumGameScreen
export const kRelativeSumLists = {
    looseAdvices: [
        "En math, on priorise la justesse avant la vitesse.",
        "N'hésite pas à vérifier tes calculs sur une feuille.",
        "Rappel n°1 : Quand tu additionnes deux nombres négatifs le résultat est négatif.",
        "Rappel n°2 : Quand tu additionnes un nombre positif et un nombre négatif le résultat est du même signe que le nombre le plus éloigné de 0.",
    ],
    recordAdvices: [
        "Félicitations.",
        "Tu maitrises parfaitement l'addition des nombres relatifs.",
        "N'hésite pas à revenir si tu as besoin de travailler tes réflexes.",
    ]
}