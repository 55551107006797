import GamesResultImage from "../../images/games/GamesResultImage";
import GamesOverAppreciationText from "../../texts/games/GamesOverAppreciationText";
import {GamesOverText} from "../../../enums/GamesOverText";
import GamesOverScoreCounterText from "../../texts/games/GamesOverScoreCounterText";
import SystemCustomDivider from "../../dividers/SystemCustomDivider";
import GamesResultsSectionText from "../../texts/games/GamesResultsSectionText";
import GamesAdvicesList from "../../lists/games/GamesAdvicesList";
import {kGamesLists} from "../../../constants/lists";
import {kGamesTexts} from "../../../constants/texts";
import GamesResultsContentLayout from "../../layouts/games/GamesResultsContentLayout";

function GamesSuccessResultContent({finalScore}) {
    // Used in MultiplicationResults to display lost game
    // content

    // BUILD
    return (
        <div className={"w-full flex flex-col justify-start items-center"}>
            <GamesResultImage isLost={false} />
            <GamesResultsContentLayout>
                <GamesOverAppreciationText gamesOverText={GamesOverText.SUCCESS} />
                <GamesOverScoreCounterText finalScore={finalScore} />
                <SystemCustomDivider
                    color={"bg-greyLight5"}
                    extraClass={"mt-16"} />
                <GamesResultsSectionText text={kGamesTexts.myNotes} />
                <GamesAdvicesList advicesList={kGamesLists.successAdvices} />
            </GamesResultsContentLayout>
        </div>
    );
}

// EXPORT
export default GamesSuccessResultContent