import GamesResultImage from "../../images/games/GamesResultImage";
import GamesAnsweredQuestionText from "../../texts/games/GamesAnsweredQuestionText";
import GamesWrongAnswerText from "../../texts/games/GamesWrongAnswerText";
import GamesOverAppreciationText from "../../texts/games/GamesOverAppreciationText";
import {GamesOverText} from "../../../enums/GamesOverText";
import SystemCustomDivider from "../../dividers/SystemCustomDivider";
import GamesResultsSectionText from "../../texts/games/GamesResultsSectionText";
import GamesAdvicesList from "../../lists/games/GamesAdvicesList";
import {kGamesTexts} from "../../../constants/texts";
import GamesResultsContentLayout from "../../layouts/games/GamesResultsContentLayout";

function GamesLooseResultContent({lastQuestion, finalScore, lastAnswer, advicesList}) {
    // Used in MultiplicationResults to display lost game
    // content

    // BUILD
    return (
        <div className={"w-full flex flex-col justify-start items-center"}>
            <GamesResultImage isLost={true} />
            <GamesResultsContentLayout>
                <GamesOverAppreciationText gamesOverText={GamesOverText.LOST} />
                <GamesAnsweredQuestionText lastQuestion={lastQuestion} />
                <GamesWrongAnswerText
                    lastAnswer={lastAnswer}
                    finalScore={finalScore} />
                <SystemCustomDivider
                    color={"bg-greyLight5"}
                    extraClass={"mt-16"} />
                <GamesResultsSectionText text={kGamesTexts.myAdvices} />
                <GamesAdvicesList advicesList={advicesList} />
            </GamesResultsContentLayout>
        </div>
    );
}

// EXPORT
export default GamesLooseResultContent