import GamesOverAppreciationText from "../../texts/games/GamesOverAppreciationText";
import {GamesOverText} from "../../../enums/GamesOverText";
import SystemCustomDivider from "../../dividers/SystemCustomDivider";
import GamesResultsSectionText from "../../texts/games/GamesResultsSectionText";
import GamesAdvicesList from "../../lists/games/GamesAdvicesList";
import GamesOverScoreCounterText from "../../texts/games/GamesOverScoreCounterText";
import GamesResultsContentLayout from "../../layouts/games/GamesResultsContentLayout";
import GamesRecordResultImage from "../../images/games/GamesRecordResultImage";

function GamesRecordResultContent({finalScore, advicesList}) {
    // Used in MultiplicationResults to display game record beaten
    // content

    // BUILD
    return (
        <div className={"w-full flex flex-col justify-start items-center"}>
            <GamesRecordResultImage />
            <GamesResultsContentLayout>
                <GamesOverAppreciationText gamesOverText={GamesOverText.RECORD} />
                <GamesOverScoreCounterText finalScore={finalScore} />
                <SystemCustomDivider
                    color={"bg-greyLight5"}
                    extraClass={"mt-16"} />
                <GamesResultsSectionText text={"Mes remarques :"} />
                <GamesAdvicesList advicesList={advicesList} />
            </GamesResultsContentLayout>
        </div>
    );
}

// EXPORT
export default GamesRecordResultContent