import {kAppStoreLink} from "../../../constants/links";
import {kSystemImages} from "../../../constants/images";

function MobileRedirectAppStoreLinkImage() {
    // Used in MobileRedirectScreen to display a clickable App Store
    // link

    // BUILD
    return (
        <a href={kAppStoreLink} target="_blank" rel="noopener noreferrer">
            <div className={"w-[200px] flex items-center justify-center"}>
                <img className={"object-contain h-full"}
                     src={kSystemImages.getItOnAppStore}
                     alt="Disponible sur l'App store" />
            </div>
        </a>
    );
}

// EXPORT
export default MobileRedirectAppStoreLinkImage