import {isNullOrUndefined} from "../../../services/Validator";

function GamesResultsLayout({children}) {
    // Used in Games to frame results content

    // BUILD
    if (isNullOrUndefined(children))
        return null
    return (
        <div className={"bg-backgroundLight3 w-[560px] md:w-[640px] lg:w-[720px] grow flex flex-col justify-center items-center pb-48"}>
            {children}
        </div>
    );
}

// EXPORT
export default GamesResultsLayout