import GamesTopBar from "../../bars/games/GamesTopBar";
import MultiplicationGameAnsweringBlock from "./MultiplicationGameAnsweringBlock";
import MultiplicationGameKeyboard from "./MultiplicationGameKeyboard";
import GamesContentLayout from "../../layouts/games/GamesContentLayout";

function MultiplicationGameContent({currentQuestion, currentScore, userAnswer, gameTimeLeft, onKeyPressed}) {
    // Used in MultiplicationGameScreen to handle core game
    // content made of top bar, answering block and
    // keyboard

    // BUILD
    return (
        <div className={"bg-backgroundLight3 h-[100svh] w-full flex flex-col justify-center items-center"}>
            <GamesContentLayout>
                <GamesTopBar currentScore={currentScore}
                             gameTimeLeft={gameTimeLeft} />
                <MultiplicationGameAnsweringBlock
                    currentQuestion={currentQuestion}
                    userAnswer={userAnswer} />
                <MultiplicationGameKeyboard
                    userAnswer={userAnswer}
                    onKeyPressed={onKeyPressed} />
            </GamesContentLayout>
        </div>
    );
}

// EXPORT
export default MultiplicationGameContent