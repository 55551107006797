import GamesScoreIcon from "../../icons/games/GamesScoreIcon";

function GamesCurrentScore({currentScore}) {
    // Used in

    // BUILD
    return (
        <div className={"flex space-x-4 justify-start items-center"}>
            <GamesScoreIcon />
            <span className={"text-greyLight7 text-[18px]"}>
                {":"}
            </span>
            <span className={"font-medium text-primaryLight1 text-[22px] pl-2"}>
                {currentScore}
            </span>
        </div>
    );
}

// EXPORT
export default GamesCurrentScore