import MultiplicationKeyItem from "../../items/multiplicationGameScreen/MultiplicationKeyItem";

function MultiplicationGameKeyboard({userAnswer, onKeyPressed}) {
    // Used in MultiplicationGameContent to display
    // specific keyboard fo this game

    // CONSTANTS
    const keys = [
        '1', '2', '3',
        '4', '5', '6',
        '7', '8', '9',
        'Effacer', '0', 'Valider'
    ];

    // BUILD
    return (
        <div className="w-full grid grid-cols-3 gap-[16px]">
            {keys.map((item, index) => (
                <MultiplicationKeyItem key={index}
                                       item={item}
                                       userAnswer={userAnswer}
                                       onKeyPressed={onKeyPressed} />
            ))}
        </div>
    );
}

// EXPORT
export default MultiplicationGameKeyboard